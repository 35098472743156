<template>
    <container>
        <div slot="left_box_content">
            <component :is="curLeftComponent" ></component>
        </div>
        <div slot="right_box_content">
            <component :is="curRightComponent" ></component>
        </div>
        <div slot="bottom_box_content">
            <component :is="curBottomComponent"></component>
        </div>
    </container>
</template>

<script>
import Container from '@/components/common/Container'
import { sysMixins } from '@/assets/mixins/sys'
import reconciliationDemoStoreModule from '@/store/modules/reconciliationDemo'

export default {
  mixins: [sysMixins],
  components: {
    Container,
    Left: () => import('./components/Left'),
    Right: () => import('./components/Right'),
    Bottom: () => import('./components/Bottom')
  },
  created () {
    // 注册动态store模块
    if (!this.$store.hasModule('reconciliationDemo')) {
      this.$store.registerModule('reconciliationDemo', reconciliationDemoStoreModule)
    }
  },
  mounted () {
    this.setLeftComponent('Left')
    this.setShowLeftBlock(true)
    this.setRightComponent('Right')
    this.setShowRightBlock(true)
    this.setBottomHeight('90%')
    this.setBottomComponent('Bottom')
    this.setShowMap(false)
  },
  destroyed () {
    // 卸载动态store模块
    this.$store.unregisterModule('reconciliationDemo')
  }
}
</script>
