const reconciliationDemo = {
  state: {
    pageType: '',
    moduleType: '',
    beginUpdate: 1,
    reloadList: 1, // 重新加载列表
    transactionReconciliationQuery: {
      executeStatus: -1,
      queryString: '',
      startDate: '',
      endDate: '',
      companyId: 0
    },

    detailBean: 0, // 对账详情实体
    maintenanceReconciliationQuery: {},
    notAllowReconciliationQuantity: 0 // 不允许对账条目的勾选数量
  },
  mutations: {
    set_reconciliationDemo_pageType (state, data) {
      state.pageType = data
    },
    set_reconciliationDemo_moduleType (state, data) {
      state.moduleType = data
    },
    set_reconciliationDemo_update (state, data) {
      state.beginUpdate = data
    },
    set_reconciliationDemo_reloadList (state, data) {
      state.reloadList = data
    },
    set_reconciliationDemo_transactionReconciliationQuery (state, data) {
      state.transactionReconciliationQuery = data
    },
    set_reconciliationDemo_detailBean (state, data) {
      state.detailBean = data
    },
    set_reconciliationDemo_maintenanceReconciliationQuery (state, data) {
      state.maintenanceReconciliationQuery = data
    },
    set_reconciliationDemo_notAllowReconciliationQuantity (state, data) {
      state.notAllowReconciliationQuantity = data
    }
  },
  getters: {

  },
  actions: {

  }
}

export default reconciliationDemo
